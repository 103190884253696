import React from "react"
import Link from "gatsby-link"

export default function Home() {
  return  <div>
          Hello world!asdasdasd
          <br/>
          <Link to='/'>
          Home
          </Link>

          </div>
}
